import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./servis.scss"

export default ({ data }) => {
  const { t } = useTranslation()
  return (
    <Layout className="page servis">
      <SEO title={t("service.headline")} />
      <h1>
        <Trans defaultMessage="Cykloservis" i18nKey="service.headline" />
      </h1>
      <br />
      <h2>
        <Trans
          defaultMessage="Celková prohlídka kola"
          i18nKey="service.subheadline"
        />{" "}
        - 690 Kč
      </h2>
      <h3>
        <Trans i18nKey="service.listheadline" />
      </h3>

      <ul>
        {t("service.list", { returnObjects: true }).map(item => (
          <li>{item}</li>
        ))}
      </ul>
      <Link className="servis__cta" to={t("service.cta")}>
        <Trans i18nKey="come" />
      </Link>
      <Img
        fluid={data.file.childImageSharp.fluid}
        className="servis__image"
        alt="Background image"
      />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    file(relativePath: { eq: "servis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
